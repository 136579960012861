import React from 'react';
import AuthorCard from './AuthorCard';

const PascalCard = () => (
  <AuthorCard
    name="Pascal Haller"
    imageUrl="/imgs/other/pascal-foto.webp"
    description="Pascal begegnet als Führungskraft im Sozialwesen täglich Menschen mit kognitiven und sprachlichen Einschränkungen und berichtet im Blog über seine Erfahrungen und gibt Hilfestellungen."
  />
);

export default PascalCard;
